import React, {useEffect} from 'react'
import Moment from 'react-moment'
import MeetNavigation from "../../components/meetNavigation";
import {useLazyQuery} from "@apollo/client";
import {QUERY_APPOINTMENTS_BY_TENANT_ID} from "../../graphql/clickAndMeetGraphQL";
import MeetNextMeeting from "../../components/meetNextMeeting";


export default function Dashboard() {

    const [loadAppointments, { queryLoading, queryError, data }] = useLazyQuery(QUERY_APPOINTMENTS_BY_TENANT_ID )

    useEffect(() => {
        loadAppointments( {
            variables: {
                id: 1
            }
        })
    }, []);


    if (queryLoading) {
        console.log(queryLoading)
    }

    if (queryError != null) {
        console.log(queryError)
    }

    return (
        <main className="max-w-7xl mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <MeetNavigation/>
                </aside>

                {/* dashboard details */}
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <MeetNextMeeting />

                    <section aria-labelledby="meetings-overview">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Client
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Service
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data != undefined && data.appointments.map((appointment, personIdx) => (

                                                <tr key={personIdx} className={(personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' cursor-pointer' }>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top">
                                                        <div className="text-sm text-gray-900">
                                                            <Moment
                                                                format='DD.MM.YYYY'
                                                                date={appointment.Moment}
                                                            />{' '}
                                                            um{' '}
                                                            <Moment
                                                                format='HH:mm'
                                                                date={appointment.Moment}
                                                            />
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            <Moment
                                                                format='DD.MM.YYYY'
                                                                date={appointment.created_at}
                                                            />{' '}
                                                            um{' '}
                                                            <Moment
                                                                format='HH:mm'
                                                                date={appointment.created_at}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 h-10">
                                                        <div className="text-sm text-gray-900">{appointment.FirstName} {appointment.LastName}</div>
                                                        <div className="text-sm text-gray-500">{appointment.Phone}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 align-top">
                                                        <div className="text-sm text-gray-900">{appointment.Service.Name}</div>
                                                        <div className="text-sm text-gray-500">{appointment.Member.Name}</div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            {appointment.Approved ? 'approved' : 'waiting'}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    )
}
