import React, {useEffect} from 'react'
import {useLazyQuery} from "@apollo/client";
import {QUERY_NEXT_APPOINTMENTS_BY_TENANT_ID} from "../graphql/clickAndMeetGraphQL";
import {Link} from "gatsby";
import Moment from "react-moment";

export default function MeetNextMeeting() {

    const [loadNextAppointment, { queryLoading, queryError, data }] = useLazyQuery(QUERY_NEXT_APPOINTMENTS_BY_TENANT_ID )

    useEffect(() => {
        loadNextAppointment( {
            variables: {
                id: 1
            }
        })
    }, []);


    if (queryLoading) {
        console.log(queryLoading)
    }

    if (queryError != null) {
        console.log(queryError)
    }

    return (
        <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
                    {
                        data != undefined && data.appointments[0] && (
                            <p className="text-sm font-medium text-gray-500">
                                Your {' '}
                                <Link to={"/meet/" + data.appointments[0].id} className="text-gray-900">
                                    Next Meeting
                                </Link>{' '}
                                is scheduled on <Moment
                                format='DD.MM.YYYY'
                                date={data.appointments[0].Moment}
                            />{' '}
                                um{' '}
                                <Moment
                                    format='HH:mm'
                                    date={data.appointments[0].Moment}
                                />
                            </p>
                        )
                    }
                </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Link
                    to={'/meet/new'}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                    New Meeting
                </Link>
            </div>
        </div>
    )
}
